<!-- 仓库出库表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 仓库名称 -->
      <FormItem>
        <span>仓库名称：</span>
        <Select placeholder="请输入" v-model="queryFrom.warehouse_id" clearable filterable @on-change="inventoryProductLookup" class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseNameList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 出库人 -->
      <FormItem class="marginLeft60">
        <span>出库人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in publicSpecialUserList" :value="item.user_name" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 出库时间 -->
      <FormItem class="marginLeft60">
        <span>出库时间：</span>
        <DatePicker type="date" placeholder="请选择" :value="queryFrom.create_time_start" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" :options="options" :value="queryFrom.create_time_end" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 产品编码  -->
      <FormItem>
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable :disabled="!queryFrom.warehouse_id" class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in inventoryProductLookupList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="product_code_array" :max-tag-count="product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table @on-sort-change="sortHospital" :columns="listColumns" :data="listData" class="table" border show-summary :summary-method="handleSummary"></Table>
      <div class="marginTop20" v-if="listData.length != 0">
        <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <div class="fr totalFont">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-else>
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'warehouseOutTableConsign',
  data() {
    return {
      Loagin: false,
      modelList: [],
      product_code_array: [],
      tabloading: false,
      // 总条数
      total: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '仓库名称',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '出库品种',
          key: 'product_kind',
          align: 'center',
        },
        {
          title: '出库总量',
          key: 'total_number',
          align: 'center',
        },
        {
          title: '出库金额',
          key: 'total_amount',
          sortable: 'custom',
          align: 'center',
          render: (h, param) => {
            // let str = param.row.total_amount ? '¥' + this.$utils.formatMoney(param.row.total_amount, true) : ''
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h('span', str)
          },
        },
        {
          title: '出库比重',
          key: 'ratio',
          align: 'center',
          render: (h, param) => {
            let str = param.row.ratio || ''
            return h('span', str)
          },
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 150,
          maxWidth: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 仓库名称列表
      warehouseNameList: [],
      // 出库人列表
      publicSpecialUserList: [],
      // 产品名称列表
      inventoryProductLookupList: [],
      // 查询参数
      queryFrom: {
        warehouse_id: undefined,
        create_by: '',
        product_id: undefined,
        create_time_start: '',
        create_time_end: '',
        model_name: '',
        product_name: '',
        sort_type: '',
        sort_field: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      options: {},
    }
  },
  mounted() {
    // this.query()
    this.permissionWarehouseList()
    this.publicSpecialUser()
    this.queryProductCodeSelect()
  },
  methods: {
    sortHospital(data) {
      this.queryFrom.sort_type = data.order
      this.queryFrom.sort_field = data.key
      this.query()
    },
    // 产品名称改变
    productChange(e) {
      if (!e) {
        this.queryFrom.model_name = null
        this.queryFrom.product_name = null
      }
      this.product_code_array = []
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.acceptanceCodeSelect, { product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    /**
     * 参数1：表头信息，即 listColumns
     * 参数2：每行数据，以行为单位
     * */
    // 导出功能
    Export() {
      if (this.Loagin) {
        return
      }
      let data = {
        ...this.queryFrom,
      }
      this.Loagin = true
      this.$http.downFile(this.$apiConsign.getWarehouse_out_export, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '仓库出库表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    handleSummary({ columns, data }) {
      // console.log(columns, data);
      const sums = {}
      columns.forEach((column, index) => {
        // console.log(column, index);
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        /**
         * 自定义合计
         * */
        // 名称列不参与合计
        if (index === 1) {
          sums[key] = {
            key,
            value: '',
          }
          return
        }
        // 出库金额列加￥
        if (index === 4) {
          let money = 0
          data.forEach(item => {
            money += item.total_amount
          })
          sums[key] = { key, value: '¥' + this.$utils.formatMoney(money) }
          return
        }
        // 比重列合计加%
        if (index === 5) {
          // 解决js精度问题
          let formatFloat = function (f, digit) {
            let m = Math.pow(10, digit)
            return Math.round(f * m, 10) / m
          }
          let ratio = 0
          data.forEach(item => {
            ratio = formatFloat(ratio + parseFloat(item.ratio), 2)
          })
          // console.log('总：', ratio);
          sums[key] = isNaN(ratio) ? { key, value: '' } : { key, value: parseInt(ratio) + '%' }
          return
        }

        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: v,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsWarehouseOutTableConsign',
        query: {
          warehouse_id: row.warehouse_id,
          create_time_start: this.queryFrom.create_time_start,
          create_time_end: this.queryFrom.create_time_end,
        },
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.create_time_start = e
        // 清空截止日期
        if (e > this.queryFrom.create_time_end) {
          this.queryFrom.create_time_end = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start) - 86400000
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    // 改变仓库名称
    inventoryProductLookup(e) {
      this.queryFrom.product_id = undefined
      if (!e) return
      // 获取产品名称下拉
      this.$http.get(this.$apiConsign.inventoryProductLookup, { warehouse_id: this.queryFrom.warehouse_id }).then(res => {
        this.inventoryProductLookupList = res.data
      })
    },
    // 获取出库人列表
    publicSpecialUser() {
      this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: '3' }).then(res => {
        this.publicSpecialUserList = res.data
      })
    },
    // 获取仓库列表
    permissionWarehouseList() {
      this.$http.get(this.$apiConsign.permissionWarehouseList, { page: 1, rows: 1000 ,is_consign:1}, true).then(res => {
        this.warehouseNameList = res.data
      })
    },
    // 分页改变
    changePage(e) {
      this.pages.page = e
      this.query()
    },
    // 查询
    query() {
      this.tabloading = true
      let data = {}
      // 规格型号
      this.queryFrom.model_name = this.product_code_array ? this.product_code_array.join(',') : null

      // 产品名称
      if (this.queryFrom.product_id) {
        let obj = this.inventoryProductLookupList.find(item => {
          return item.product_id == this.queryFrom.product_id
        })
        this.queryFrom.product_name = obj.product_name
      }
      Object.assign(data, this.queryFrom, this.pages)
      this.$http.get(this.$apiConsign.warehouseOut, data).then(res => {
        this.tabloading = false
        this.listData = res.data
        this.total = res.total
      })
    },
  },
}
</script>

<style scoped lang="less">
.marginTop20 {
  margin-top: 20px;
}
.totalFont {
  font-size: 14px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
